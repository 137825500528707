// body
$body_bg: #F5F5F5;

// accent color
$accent_color: #ffffff;
$accent_bg_color: #286BDD;

// title
$title_color: #000000;

// cards
$cards_bg_color: #ffffff;
$cards_bg_color_hover: #ffffff;
$cards_title_color: rgba(0, 0, 0, 0.4);
$cards_btn_icon_color: #ffffff;
