.header {
	margin-bottom: 50px;
	@include md {
		margin-bottom: 30px;
	}
	@include sm {
		margin-bottom: 20px;
	}
	&__wrap {
		max-width: 844px;
		width: 100%;
		margin: 0 auto;
		@include md {
			max-width: 100%;
			padding: 0 10px;
		}
		@include sm {
			padding: 0 5px;
		}
	}
	&__bg {
		width: 100%;
		height: 0;
		padding-bottom: 43%;
		overflow: hidden;
		border-radius: 10px;
		margin-top: -28px;
		background-color: $accent_bg_color;
		position: relative;
		@include md {
			margin-top: 10px;
		}
		@include sm {
			margin-top: 5px;
		}
		img {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
		}
	}
	&__lang {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 35px;
		height: 35px;
		border-radius: 50%;
		position: absolute;
		top: 35px;
		right: 7px;
		z-index: 1;
		@include md {
			top: 10px;
			right: 10px;
		}
		@include sm {
			width: 25px;
			height: 25px;
			top: 5px;
			right: 5px;
		}
		&:after {
			display: block;
			content: '';
			width: 100%;
			height: 100%;
			background-color: $accent_bg_color;
			border: 1px solid $accent_bg_color;
			border-radius: 50%;
			position: absolute;
			top: 0;
			left: 0;
			transition: all .3s ease-in-out;
			z-index: -1;
		}
		span {
			font-family: e-Ukraine-Light;
			font-size: 12px;
			line-height: 14px;
			color: $accent_color;
			transition: all .3s ease-in-out;
			@include sm {
				font-size: 10px;
				line-height: 12px;
			}
		}
		&:hover,
		&:active {
			span {
				opacity: .6;
			}
			&:after {
				transform: scale(1.1);
			}
		}
	}
	&--custom &__lang {
		&:after {
			border: 1px solid 1px solid rgba(255, 255, 255, .3);
		}
	}
	&__logo {
		display: block;
		content: '';
		width: 160px;
		height: 160px;
		margin: -80px auto -30px;
		border-radius: 50%;
		border: 1px solid rgba(207, 207, 207, .2);
		position: relative;
		@include sm {
			margin: -80px auto -17px;
		}
	}
	&__logo-img {
		width: 140px;
		height: 140px;
		padding: 20px;
		border-radius: 50%;
		background-color: #ffffff;
		overflow: hidden;
		transform: translate(9px, 9px);
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
	&__title {
		font-family: e-Ukraine-Regular;
		font-size: 36px;
		line-height: 43px;
		text-align: center;
		color: $title_color;
		margin-top: 47px;
		@include md {
			padding: 0 20px;
		}
		@include sm {
			font-size: 21px;
			line-height: 25px;
			margin-top: 29px;
			margin-bottom: 10px;
		}
	}
	&--custom &__title {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		padding: 78px 80px 50px;
		color: $accent_color;
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;
		z-index: 1;
		@include md {
			padding: 50px 70px;
		}
		@include sm {
			padding: 35px 20px;
		}
	}
}